<script setup lang="ts">
import VSkeleton from "@magnit/core/src/components/VSkeleton/VSkeleton.vue";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";

const times = [1, 2, 3];
</script>

<template>
  <VWrapperLocal v-for="s in times" :key="s" class="skeleton-popular-wrap">
    <VStack>
      <VStackItem>
        <VSkeleton :mt="5" width="300px" height="45px" />
      </VStackItem>
    </VStack>
    <VStack>
      <VStackItem>
        <VSkeleton :mt="6" :mb="8" width="150px" />
      </VStackItem>
    </VStack>
    <VStack direction="row">
      <VStackItem>
        <VSkeleton :radius="12" width="300px" height="300px" />
      </VStackItem>
      <VStackItem :size="8">
        <VStack direction="column">
          <VStackItem :size="8">
            <VSkeleton height="30px" />
          </VStackItem>
          <VStackItem :size="4">
            <VSkeleton height="30px" />
          </VStackItem>
        </VStack>
      </VStackItem>
    </VStack>
  </VWrapperLocal>
</template>

<style scoped lang="postcss">
.skeleton-popular-wrap {
  display: flex;
  flex-direction: column;
  gap: var(--pl-unit-x5);
  width: 100%;
}
</style>
